import * as React from 'react';
import cx from 'clsx';
import { useTheme, makeStyles } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import YoutubeEmbed from '../ThirdParty/YoutubeEmbed/YoutubeEmbed.js';

export default function MainCard(props) {
  const theme = useTheme();
  const {
    card
  } = props

  if (!card) return;

  const { title, subtitle, description, content, media } = card;


  return (
      <Card raised sx={{ display: 'flex', width: '100%' }} onClick={(e)=>{e.stopPropagation();}}>
        <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
          {media}
          <Typography component="div" variant="h5" my={subtitle ? 0 : 3} mt={3}>
            {title}
          </Typography>
          {subtitle ?
            (<Typography variant="subtitle1" sx={{ textAlign: 'center' }} mb={3}>
              {subtitle}
            </Typography>)
          :
            null
          }
          <Typography variant="body1" color="text.primary" component="div" textAlign="left">
            {content}
          </Typography>
        </CardContent>
      </Card>
  );
}
