import * as React from 'react';
import cx from 'clsx';
import { useTheme, makeStyles } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   // height: 60,
//   // lineHeight: '60px',
//   margin: '10px 0'
// }));

// const useStyles = makeStyles(() => ({
//   root: {
//     maxWidth: 343,
//     margin: 'auto',
//     borderRadius: 30,
//     padding: 12,
//   },
//   media: {
//     borderRadius: 6,
//   },
// }));

export default function PreviewCard(props) {
  // const styles = useStyles();
  const theme = useTheme();
  const { title, thumbnail } = props.card;

  return (
      <Card raised sx={{ display: 'flex' }}>
        <CardActionArea onClick={props.onClick}>
          <CardMedia
            component="img"
            sx={{ objectFit: "cover" }}
            image={thumbnail}
            alt={title}
          />
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h6">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
  );
}
