import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import YoutubeEmbed from '../ThirdParty/YoutubeEmbed/YoutubeEmbed.js';

const defaultMedia = (
    <CardMedia
      component="img"
      sx={{ objectFit: 'cover', width: '100%' }}
      image="/static/img/test_card_image.png"
    />
)

export default [
  {
    title: "Treehouse Games | Technical Lead - Construction/Build Team",
    media: (
      <YoutubeEmbed embedId="9SyqNI2FYRo" autoPlay={true} />
    ),
    thumbnail: '/static/img/Thumb_VoyagersOfNera.jpg',
    content: (
      <div>
        <p>This is a pre-alpha trailer for Voyagers of Nera, the game I served as technical lead for at Treehouse Games.</p>
        <p>Construction/Build team content is featured from 0:40 - 0:49.</p>
        <p>I worked on a lot of areas of the game, but I spent the most time with the construction/build system.  I focused on making it easier for artists and designers to make and maintain lots of buildables, and on a "customization" feature that allowed the player to customize the meshes and materials of buildables on the fly.</p>
      </div>
    ),
  },
  {
    title: "Shipped Title - WithMe",
    media: (
      <YoutubeEmbed embedId="rQ3d0FfK4as" autoPlay={true} />
    ),
    thumbnail: '/static/img/Thumb_Imvu.png',
    content: (
      <div>
        <p><i>Please forgive the quality of the video - it's one of the last ones up on YouTube.</i></p>
        <p>WithMe launched Q1 2023 as a companion app to Together Lab’s flagship product, IMVU.  WithMe aimed to bring a modern, immersive social experience IMVU players and featured advanced character customization and mobile cross-platform play.</p>
        <p>I spent my first months taking various gamification systems from start to finish, most notably daily quests and activity-specific challenges.</p>
        <p>After two rounds of layoffs, I worked on tooling to allow artists to quickly import and update avatar assets (clothes, accessories, etc) via a wizard.</p>
        <p>After a third round of layoffs, I and a skeleton crew of engineers shipped the game.  Unfortunately, Together labs ended up taking the game down some time after I left the company in July.</p>
      </div>
    ),
  },
  {
    title: "Top-Down Combat Prototype",
    media: (
      <YoutubeEmbed embedId="N-y-pnQH2Uw" autoPlay={true} />
    ),
    thumbnail: '/static/img/Thumb_TopDownPrototype.png',
    content: (
      <div>
        <p>In this project, I sought to reproduce the combat of <a href="https://store.steampowered.com/app/1037020/ScourgeBringer/">Scourgebringer</a> - a game with some really great combat gameplay, but in the top-down format.  I think I achieved this fairly well - the moment-to-moment gameplay is punchy and fun.</p>
        <p>I challenged myself to use only basic primitives so I could focus on gameplay look and feel without getting distracted.  Even the character is just some cubes rigged to a human skeleton, and the hit effects are just primitives with some vertex data baked into them to assist with driving motion via materials.</p>
        <p>For fun, I also played around with lining up the beat of the music to visuals - nothing fancy, just taking beat & time signature information and making it available to materials.  I also wrote the music and authored the sound effects.</p>
        <p>I had no budget or team, and while the whole thing is obviously modest, I'm proud to have achieved what I set out to do here.  I'd love to spend some time more incorporating art, pushing combat further, and putting it in the container of a proper game!</p>
      </div>
    ),
  },
  {
    title: "Various 2.5D Prototypes",
    media: (
      <YoutubeEmbed embedId="U9v_aB1ZeTg" autoPlay={true} />
    ),
    thumbnail: '/static/img/Thumb_25DCharacterController.png',
    content: (
      <div>
        <p>This is a collection of snippets from various 2.5D platformer projects I've played with over the years.</p>
        <p>These served as great containers for learning how to make games.  I let myself shoot for the stars with each one of these and learned a lot about making games in the process.</p>
        <p>Note that all content shown was authored by me - I didn't use any plugins or asset packs (even though doing so could have saved me a lot of time) because it was important to me to learn.</p>
      </div>
    ),
  },
  // {
  //   title: "Virtual Camera System",
  //   media: (
  //       <CardMedia
  //         component="img"
  //         sx={{ objectFit: 'cover', width: '100%' }}
  //         image="/static/img/Thumb_VirtualCamera.png"
  //       />
  //   ),
  //   thumbnail: '/static/img/Thumb_VirtualCamera.png',
  //   content: (
  //     <ul>
  //       <li>Based on unity’s virtual camera system, have one main camera and any number of camera targets that the main camera lerps between</li>
  //       <li>Allows smoothly switching between any number of cameras with arbitrary behavior</li>
  //     </ul>
  //   ),
  // },
  // {
  //   title: "Global Events System",
  //   media: (
  //       <CardMedia
  //         component="img"
  //         sx={{ objectFit: 'cover', width: '100%' }}
  //         image="/static/img/Thumb_GlobalEvents.png"
  //       />
  //   ),
  //   thumbnail: '/static/img/Thumb_GlobalEvents.png',
  //   content: (
  //     <ul>
  //       <li>Global events system allows for any arbitrary actor / object to subscribe and broadcast to a global stream of events</li>
  //       <li>Makes updating UI, firing analytics events, listening to settings changes, etc, seamless.</li>
  //       <li>Automatically clears listeners which have been destroyed for ease of use</li>
  //     </ul>
  //   ),
  // },
  // {
  //   title: "Procedural Level Generation",
  //   media: defaultMedia,
  //   thumbnail: '/static/img/test_card_image.png',
  //   content: (
  //     <ul>
  //       <li>Procedural generation of level using Wang-tile based tile set.</li>
  //       <li>Key points of interest are organized and connected in a graph and spread out physically into worldspace using a force-directed graph layout algorithm</li>
  //       <li>Heightmap generated</li>
  //       <li>Critical path paved between key points of interest with a modified drunken walk algorithm</li>
  //       <li>Terrain features added by configurable set of decorators</li>
  //     </ul>
  //   ),
  // },
  // {
  //   title: "",
  //   content: (
  //     <ul>
  //       <li></li>
  //     </ul>
  //   ),
  // }
];
