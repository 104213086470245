import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2'
import MainCard from './Components/MainCard';
import Paper from '@mui/material/Paper';
import PreviewCard from './Components/PreviewCard';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled, useTheme  } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import MailIcon from '@mui/icons-material/MailOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import YoutubeEmbed from './ThirdParty/YoutubeEmbed/YoutubeEmbed.js';
import './ThirdParty/YoutubeEmbed/YoutubeEmbed.css';
import './App.css';
import CardData from './Data/CardData';


  // margin: 0;
  // font-family: "Roboto","Helvetica","Arial",sans-serif;
  // font-weight: 400;
  // font-size: 3rem;
  // line-height: 1.167;
  // letter-spacing: 0em;
  // text-align: left;

function App() {
  const [openCardIndex, setOpenCardIndex] = useState(-1);
  const isAvatarVisible = useMediaQuery('(min-width:360px)');

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('lg'));

  let headerSize;
  if (isMd) {
    headerSize = '6rem'
  } else if (isSm) {
    headerSize = '3.5rem'
  } else {
    headerSize = '2rem'
  }

  let avatarSize;
  if (isMd) {
    avatarSize = 180;
  } else if (isSm) {
    avatarSize = 130;
  } else {
    avatarSize = 100;
  }

  return (
    <Box className="App">
      <Container maxWidth="md">
        <Box my={isMd ? 3 : 1} sx={{ display: isAvatarVisible ? 'flex' : 'block', flexDirection: 'row', flexWrap: 'nowrap' }}>
          <Box sx={{ display: isAvatarVisible ? 'flex' : 'none' }}>
            <Avatar
              src="/static/img/headshot.jpg"
              sx={{ width: avatarSize, height: avatarSize }}
            />
          </Box>
          <Box mx={3} sx={{ display: isAvatarVisible ? 'flex' : 'block' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
              <Typography variant="h1" sx={{ textAlign: isAvatarVisible ? 'left' : 'center', fontSize: headerSize }}>
                Andrew Black
              </Typography>
              <Typography variant="body1" sx={{ textAlign: isAvatarVisible ? 'left' : 'center' }}>
                <MailIcon fontSize="inherit" /> imandrewblack@gmail.com
              </Typography>
              <Typography variant="body1" sx={{ textAlign: isAvatarVisible ? 'left' : 'center' }}>
                <a href="https://twitter.com/Bit_Rot" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}><TwitterIcon fontSize="inherit" /> @bit_rot</a>
              </Typography>
              <Typography variant="body1" sx={{ textAlign: isAvatarVisible ? 'left' : 'center' }}>
                <PlaceOutlinedIcon fontSize="inherit" /> San Francisco, CA
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider>
          <Typography my={isSm ? 3 : 1}>
            Portfolio
          </Typography>
        </Divider>
        <Box>

        </Box>
        <Grid container spacing={2}>
          {
            CardData.map((card, i) => {
              return (
                  <Grid xs={12} key={i}>
                    <PreviewCard card={card} onClick={()=>{setOpenCardIndex(i)}}/>
                  </Grid>
              );
            })
          }
        </Grid>
      </Container>
      <Backdrop open={openCardIndex >= 0} onClick={()=>{setOpenCardIndex(-1)}}>
        <Container>
          <MainCard card={CardData[openCardIndex]} />
        </Container>
      </Backdrop>
    </Box>
  );
}

export default App;
